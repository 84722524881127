<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    permanent
    width="100%"
    height="100svh"
    style="z-index: 9999!important; overflow: hidden"
  >
    <div
      style="
        background-color: #f8f8f8;
        padding: 60px 20px 80px 20px;
        text-align: center;
        min-height: 100svh;
        height:max-content;
        z-index: 150;
        position:relative;
      "
    >
      <v-icon
        size="30px"
        color="#1b1b1b"
        style="position: absolute; right: 20px; top: 20px"
        @click="$emit('close')"
        >mdi-close</v-icon
      >
      <p class="title">
        Слідкуйте за нами та дізнавайтеся актуальну інформацію
      </p>
      <v-col class="px-0 py-0" style="margin-top: 32px">
        <a
          v-for="social in socialNetworks"
          :key="social.id"
          :href="social.value"
          target="_blank"
          style="text-decoration: none; color: #1b1b1b"
        >
          <v-row no-gutters align="center" style="margin-bottom: 20px">
            <v-col cols="1" style="text-align: center">
              <v-row no-gutters align="center" justify="center">
                <img
                  :src="social.image_id.path"
                  width="24px"
                  height="24px"
                  alt="social icon"
                />
              </v-row>
            </v-col>
            <v-col cols="9" style="text-align: left; padding-left: 11px">
              <span
                style="
                  color: #0d0d0d;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                "
                >{{ social.key }}</span
              >
            </v-col>
            <v-col cols="2">
              <v-row no-gutters align="center" justify="end">
                <v-icon color="#1B1B1B" size="24px">mdi-chevron-right</v-icon>
              </v-row>
            </v-col>
          </v-row>
        </a>
      </v-col>
      <p class="title">Служба підтримки клієнтів</p>
      <v-col class="px-0 py-0" style="margin-top: 32px">
        <a
          :href="`tel: ${number.value}`"
          style="text-decoration: none; color: #1b1b1b"
          v-for="number in phoneNumbersForOrder"
          :key="number.id"
        >
          <v-row no-gutters align="center" style="margin-bottom: 20px">
            <v-col cols="1" style="text-align: center">
              <v-row no-gutters align="center" justify="center">
                <img
                  v-if="number.type == 'UA'"
                  src="@/assets/img/UA.png"
                  class="ml-2"
                  width="24px"
                  height="24px"
                />
                <img
                  v-if="number.type == 'PL'"
                  src="@/assets/img/PL.png"
                  class="ml-2"
                  width="24px"
                  height="24px"
                />
              </v-row>
            </v-col>
            <v-col cols="9" style="text-align: left; padding-left: 11px">
              <span
                style="
                  color: #0d0d0d;
                  font-family: Roboto;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                "
                >{{ number.value }}</span
              >
            </v-col>
            <v-col cols="2">
              <v-row no-gutters align="center" justify="end">
                <v-icon color="#1B1B1B" size="24px">mdi-chevron-right</v-icon>
              </v-row>
            </v-col>
          </v-row>
        </a>
      </v-col>
      <v-row
        no-gutters
        justify="center"
        style="margin-top: 50px"
      >
        <img
          src="@/assets/img/logoMbus.png"
          width="115px"
          height="80px"
          alt="logo"
        />
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
  }),
  props: {
    socialNetworks: {
      require: true,
    },
    phoneNumbersForOrder: {
      require: true,
    },
  },
};
</script>

<style scoped>
.title {
  color: #085895;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
</style>
<template>
  <v-row align="center" style="z-index: 120">
    <!-- <div
      style="
        background-color: white;
        width: 40px;
        height: 40px;
        cursor: pointer;
        z-index: 120;
        display: grid;
        place-items: center;
        border: 1.5px solid #bcbcbc;
        border-radius: 50%;
        position: absolute;
        margin-left: 255px;
      "
      @click="reverseItem"
    >
      <img
        src="@/assets/img/iconsSvg/swapIcon.svg"
        alt="Місце"
        width="24px"
        height="24px"
      />
    </div> -->
    <v-row justify="center">
      <v-col cols="12" xl="3" lg="3" md="3" sm="12" class="px-0 py-0">
        <v-autocomplete
          background-color="#F1F2F6"
          prepend-inner-icon="mdi-map-marker-outline"
          placeholder="Звідки"
          outlined
          dense
          class="searchRoute"
          :class="
            $vuetify.breakpoint.smAndDown
              ? 'rounded-t-lg rounded-b-0 '
              : 'rounded-l-lg rounded-r-0'
          "
          :items="Object.values(startCities)"
          :item-text="(item) => `${item.city_name}, ${item.country_name}`"
          :item-value="`city_uuid`"
          v-model="start_route"
          direction="horizontal"
          height="70px"
          :error-messages="startRouteError"
          :style="$vuetify.breakpoint.smAndDown ? '' : 'margin-right: 10px'"
          hide-details
          style=""
        >
          <template v-slot:prepend-inner>
            <img
              class="pt-1"
              src="@/assets/img/mobileMenu/placeIcon.svg"
              alt="Місце"
            />
          </template>
          <template slot="item" slot-scope="{ item }">
            <span style="font-size: 16px; line-height: 19px; color: #243949">{{
              item.city_name
            }}</span
            >,
            <span
              style="
                font-size: 16px;
                line-height: 19px;
                color: rgb(111, 111, 111);
              "
              >{{ item.country_name }}</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <div
        style="
          width: 0px;
          position: relative;
          display: flex;
          align-items: center;
        "
      >
        <div class="reverseBtn">
          <div class="reverseIcon" @click="reverseItem"></div>
        </div>
      </div>
      <!-- <div
      style="
        background-color: white;
        width: 34px;
        height: 40px;
        margin-top: 12px;
        cursor: pointer;
        border-top: 1px solid #085895;
        border-bottom: 1px solid #085895;
      "
      @click="reverseItem"
    >
      <img
        src="@/assets/img/reverseIconHorizontal.svg"
        class="mt-2"
        alt="Місце"
      />
    </div> -->
      <v-col cols="12" xl="3" lg="3" md="3" sm="12" class="px-0 py-0">
        <v-autocomplete
          placeholder="Куди"
          outlined
          dense
          class="rounded-0 searchRoute"
          :items="Object.values(nextCities)"
          :item-text="(item) => `${item.city_name}, ${item.country_name}`"
          :item-value="'city_uuid'"
          v-model="end_route"
          :disabled="!nextCities.length"
          color="#085895"
          background-color="#F1F2F6"
          height="70px"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 4px'
              : 'margin-right: 4px; margin-left: 12px;'
          "
          :error-messages="endRouteError"
          hide-details
          style=""
        >
          <template v-slot:prepend-inner>
            <img
              class="pt-1"
              src="@/assets/img/mobileMenu/placeIcon.svg"
              alt="Місце"
            />
          </template>
          <template slot="item" slot-scope="{ item }">
            <span style="font-size: 16px; line-height: 19px; color: #243949">{{
              item.city_name
            }}</span
            >,
            <span
              style="
                font-size: 16px;
                line-height: 19px;
                color: rgb(111, 111, 111);
              "
              >{{ item.country_name }}</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12" class="px-0 py-0">
        <v-menu
          ref="menu"
          v-model="showChooseDateMenu"
          bottom
          offset-y
          :nudge-left="$vuetify.breakpoint.smAndDown ? '' : '70px'"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              prepend-inner-icon="mdi-calendar"
              placeholder="Сьогодні"
              outlined
              dense
              color="#085895"
              class="rounded-0"
              :value="
                date
                  ? new Date(date).toLocaleDateString('uk-UA', {
                      month: 'short',
                      day: 'numeric',
                    })
                  : ''
              "
              v-bind="attrs"
              v-on="on"
              hide-details
              background-color="#F1F2F6"
              height="70px"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-top: 4px;margin-bottom: 4px;'
                  : 'margin-right: 4px'
              "
            >
              <template v-slot:prepend-inner>
                <img
                  @click="showChooseDateMenu = true"
                  class="pointer"
                  style="padding-top: 16px"
                  src="@/assets/img/mobileMenu/calendarIcon.svg"
                  alt="Календар"
                /> </template
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            color="#085895"
            class="disabledPickerBtn"
            no-title
            locale="uk"
            :min="
              new Date(
                new Date().getTime() +
                  1 * 60 * 60 * 1000 -
                  new Date().getTimezoneOffset() * 60000
              )
                .toISOString()
                .substr(0, 10)
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        xl="2"
        lg="2"
        md="2"
        sm="12"
        class="py-0 px-0"
        :style="
          $vuetify.breakpoint.smAndDown
            ? ''
            : 'padding-right: 8px; padding-left: 0px'
        "
      >
        <v-menu
          v-model="showQuantityPeopleMenu"
          left
          offset-y
          width="261px"
          :close-on-content-click="isClose"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              v-on="on"
              placeholder="1"
              outlined
              dense
              color="#085895"
              v-model="quantity_people"
              @click="isClose = false"
              background-color="#F1F2F6"
              height="70px"
              hide-details
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'rounded-b-lg rounded-t-0 '
                  : 'rounded-l-0 rounded-r-lg'
              "
              :error-messages="quantityPeopleError"
            >
              <template v-slot:prepend-inner>
                <v-icon
                  @click="showQuantityPeopleMenu = true"
                  class="pointer"
                  color="#085895"
                  arria-label="Кількість місць"
                  style="margin-top: 14px"
                  >mdi-account-outline</v-icon
                ></template
              ></v-text-field
            >
          </template>
          <v-card width="261px" style="padding: 15px 30px 15px 30px">
            <v-row no-gutters align="center" justify="start">
              <v-col cols="6" style="text-align: left"
                ><span class="peopleTypeStyle"
                  >Кількість пасажирів:</span
                ></v-col
              >
              <v-icon
                :color="quantityAdult == 0 ? '' : '#085895'"
                @click="
                  quantityAdult > 0 ? quantityAdult-- : (quantityAdult = 0)
                "
                >mdi-minus-circle-outline</v-icon
              >
              <output
                style="margin-left: 15px; margin-right: 15px"
                class="peopleTypeStyle"
                >{{ quantityAdult }}</output
              >
              <v-icon color="#085895" @click="quantityAdult++"
                >mdi-plus-circle-outline</v-icon
              >
            </v-row>
            <!-- <v-row no-gutters align="center" justify="start" class="mt-5">
            <v-col cols="6" style="text-align: left"
              ><span class="peopleTypeStyle">Дитячий:</span></v-col
            >
            <v-icon
              :color="quantityKid == 0 ? '' : '#085895'"
              @click="quantityKid > 0 ? quantityKid-- : (quantityKid = 0)"
              >mdi-minus-circle-outline</v-icon
            >
            <output
              style="margin-left: 15px; margin-right: 15px"
              class="peopleTypeStyle"
              >{{ quantityKid }}</output
            >
            <v-icon color="#085895" @click="quantityKid++"
              >mdi-plus-circle-outline</v-icon
            >
          </v-row> -->
          </v-card>
        </v-menu>
      </v-col>

      <v-btn
        class="searchRouteBtn"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '180px'"
        height="70px"
        color="#085895"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-top: 8px;'
            : 'margin-left: 8px;'
        "
        @click="searchRoutes"
      >
        Пошук квитків
      </v-btn>
    </v-row>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import searchRoutesService from "@/requests/main/searchRoutesService";
export default {
  mixins: [validationMixin],
  data: () => ({
    start_route: "",
    end_route: "",
    routeDate: "",
    date: "",
    quantity_people: 1,
    startCities: [],
    nextCities: [],
    quantityAdult: 1,
    quantityKid: 0,
    isClose: false,
    showQuantityPeopleMenu: false,
    showChooseDateMenu: false,
  }),
  validations: {
    start_route: {
      required,
    },
    end_route: {
      required,
    },
    //date: {
    //  required,
    //},
    quantity_people: {
      required,
    },
  },
  mounted() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.setSearchField();
    }
    this.getStartCities();
  },
  methods: {
    ...mapActions(["updateLoader"]),
    reverseItem(start_route, end_route) {
      if (start_route && end_route) {
        this.updateLoader(true);
        this.start_route = end_route;
        this.end_route = start_route;
      } else if (this.start_route && this.end_route) {
        this.updateLoader(true);
        let old_start_route = this.start_route;
        this.start_route = this.end_route;
        this.end_route = old_start_route;
      }
      setTimeout(() => {
        this.hideLoader();
      }, 500);
    },
    hideLoader() {
      this.updateLoader(false);
    },
    async getStartCities() {
      let response = await searchRoutesService.getStartCities();
      this.startCities = response.data.filter((city) => city.city !== null);
      this.hideLoader();
    },
    async getNextCities(start_route) {
      let response = await searchRoutesService.getNextCities(
        start_route ? start_route : this.start_route
      );
      this.nextCities = response.data.filter((city) => city.city !== null);
      setTimeout(this.hideLoader, 1500);
    },
    async searchRoutes(isMobile, departureCity, arrivalCity, date) {
      this.$v.$touch();
      if (isMobile && typeof isMobile !== "object") {
        this.$route.name !== "ticket_search_page"
          ? this.$router.push(
              `/tickets?departureCity=${departureCity}&arrivalCity=${arrivalCity}&date=${date}&quantity_adult=${this.quantityAdult}`
            )
          : this.$router.push(
              `/tickets?departureCity=${departureCity}&arrivalCity=${arrivalCity}&date=${date}&quantity_adult=${this.quantityAdult}`
            );
      } else if (!this.$v.$invalid) {
        this.$route.name !== "ticket_search_page"
          ? this.$router.push(
              `/tickets?departureCity=${this.start_route}&arrivalCity=${this.end_route}&date=${this.date}&quantity_adult=${this.quantityAdult}`
            )
          : this.$router.push(
              `/tickets?departureCity=${this.start_route}&arrivalCity=${this.end_route}&date=${this.date}&quantity_adult=${this.quantityAdult}`
            );
        this.hideLoader();
      }
    },
    setQuantityPeople() {
      this.quantity_people =
        this.quantityAdult == 0 ? "" : parseInt(this.quantityAdult);
    },
    setSearchField() {
      let route = this.$route.query;
      this.start_route = route?.departureCity;
      this.end_route = route?.arrivalCity;
      this.date = route?.date || "";
      this.quantityAdult = route?.quantity_adult || "0";
      this.quantityKid = 0;
    },
  },
  computed: {
    startRouteError() {
      const errors = [];
      if (!this.$v.start_route.$dirty) {
        return errors;
      }
      !this.$v.start_route.required && errors.push("");
      return errors;
    },
    endRouteError() {
      const errors = [];
      if (!this.$v.end_route.$dirty) {
        return errors;
      }
      !this.$v.end_route.required && errors.push("");
      return errors;
    },
    // dateError() {
    //   const errors = [];
    //   if (!this.$v.date.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.date.required && errors.push("");
    //   return errors;
    // },
    quantityPeopleError() {
      const errors = [];
      if (!this.$v.quantity_people.$dirty) {
        return errors;
      }
      !this.$v.quantity_people.required &&
        errors.push("Оберіть місце прибуття");
      return errors;
    },
  },
  watch: {
    start_route: {
      deep: true,
      handler() {
        if (this.start_route && this.start_route.length > 0) {
          this.getNextCities();
        }
      },
    },
    quantityAdult: {
      deep: true,
      handler() {
        this.setQuantityPeople();
      },
    },
    quantityKid: {
      deep: true,
      handler() {
        this.setQuantityPeople();
      },
    },
    quantity_people: {
      handler() {
        this.quantityAdult = this.quantity_people;
      },
    },
    "$route.path": {
      deep: true,
      handler() {
        if (Object.keys(this.$route.query).length !== 0) {
          this.setSearchField();
        }
      },
    },
    date: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.showChooseDateMenu = false;
        }, 500);
      },
    },
  },
};
</script>

<style>
.peopleTypeStyle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #243949;
}
.disabledPickerBtn
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn-outlined
  ) {
  opacity: 0 !important;
}
.searchRoute .v-input__prepend-inner {
  margin-top: 23px !important;
}
.searchRoute .v-input__icon--append .v-icon {
  margin-top: 24px !important;
}
</style>
<style scoped>
.searchRouteBtn {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: #eee !important;
  text-align: center;
  font-family: Roboto;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
}
@media screen and (max-width: 1000px) {
  .reverseBtn {
    background: white;
    border: 1px solid #bcbcbc;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 48px;
    left: -36px;
    transform: translateY(-50%);
    transform: rotate(90deg);
    z-index: 10;
  }
}
@media screen and (min-width: 1001px) {
  .reverseBtn {
    background: white;
    border: 1px solid #bcbcbc;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    z-index: 10;
  }
}
.reverseBtn:hover .reverseIcon {
  background: #085895;
}
.reverseIcon {
  width: 24px;
  height: 24px;
  background-color: #bcbcbc;
  -webkit-mask: url("@/assets/img/iconsSvg/reverseIcon.svg") no-repeat center;
  mask: url("@/assets/img/iconsSvg/reverseIcon.svg") no-repeat center;
}
</style>
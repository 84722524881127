<template>
  <button
    class="adminFormButton"
    :class="disable ? 'disableBtn' : ''"
    @click="disable ? '' : $emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disable: {
      require: false,
    },
  },
};
</script>

<style scoped>
.adminFormButton {
  display: flex;
  height: 40px;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #085895;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  width: max-content;
  min-width: 280px;
}
.adminFormButton:hover {
  background: #09426d;
}
.disableBtn {
  user-select: none;
  background-color: #848b92 !important;
}
</style>
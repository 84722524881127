<template>
  <v-dialog
    v-model="visibility"
    :width="$vuetify.breakpoint.xs ? '90%' : '600px'"
    style="overflow: hidden"
    persistent
  >
    <success-snackbar v-if="showSuccessSnackbar" :snackbarText="snackbarText" />
    <!-- <modal-header
      title="Реєстрація користувача"
      @close="$emit('close')"
      :showCloseIcon="true"
    /> -->
    <v-card style="overflow: hidden; border-radius: 10px" height="max-content">
      <v-row
        no-gutters
        align="center"
        justify="end"
        style="padding: 20px; padding-bottom: 0px"
        ><img
          class="pointer"
          @click="$emit('close')"
          src="../../../../assets/img/closeDialogIcon.svg"
          alt="close_icon"
          width="20px"
      /></v-row>
      <v-row justify="center" style="margin-top: 20px">
        <v-col cols="10" lg="10" md="10" sm="10" xl="10">
          <p class="modalTitle">Реєстрація</p>
          <p class="modalSubtitle">
            Створіть свій обліковий запис та отримайте доступ до усіх сервісів
            сайту
          </p>
          <v-text-field
            dense
            outlined
            v-model="user.username"
            color="7B8892"
            placeholder="Прізвище"
            :error-messages="usernameError"
            @blur="$v.user.username.$touch()"
          />
          <v-text-field
            dense
            outlined
            v-model="user.last_name"
            color="7B8892"
            placeholder="Імʼя"
          />
          <v-text-field
            dense
            outlined
            v-model="user.email"
            color="7B8892"
            placeholder="Email"
            :error-messages="emailError.length ? emailError : emailErros"
            @input="emailErros = []"
            @blur="$v.user.email.$touch()"
          />
          <v-tooltip bottom color="rgba(0, 0, 0, 0.8)" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                v-model="user.password"
                color="7B8892"
                placeholder="Пароль"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :error-messages="passwordError"
                @blur="$v.user.password.$touch()"
              />
            </template>
            Пароль повинен містити мінімум 8 символів, серед яких хоч одну
            цифру, спецсимвол ($,&, ... і т.п.), велику та маленьку літери
          </v-tooltip>
          <v-text-field
            dense
            outlined
            v-model="user.password_confirm"
            color="7B8892"
            placeholder="Повторити пароль"
            :type="showConfirmPassword ? 'text' : 'password'"
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            :error-messages="passwordConfirmError"
            @blur="$v.user.password_confirm.$touch()"
          />
          <v-row
            no-gutters
            :align="$vuetify.breakpoint.xs ? 'start' : 'end'"
            justify="start"
            class="py-0 mb-5"
          >
            <v-checkbox color="#085895" v-model="isAgree" />
            <v-col
              cols="10"
              lg="11"
              md="11"
              sm="11"
              xl="11"
              style="text-align: left"
              class="mb-1"
            >
              <span
                style="
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: 0.1em;
                  color: #50616e;
                  text-align: left;
                "
                >Згоден з
                <span class="linkColor"
                  ><router-link
                    to="/rules_and_general_terms"
                    target="_blank"
                    style="text-decoration-line: underline; color: #085895"
                    >Угодою користувача</router-link
                  ></span
                >та
                <span class="linkColor"
                  ><router-link
                    to="/rules_and_general_terms"
                    target="_blank"
                    style="text-decoration-line: underline; color: #085895"
                    >Обробкою моїх персональних даних</router-link
                  ></span
                >
                у відповідності з
                <span class="linkColor"
                  ><router-link
                    to="/rules_and_general_terms"
                    target="_blank"
                    style="text-decoration-line: underline; color: #085895"
                    >Політикою конфіденційності</router-link
                  ></span
                ></span
              >
            </v-col>
          </v-row>
          <v-btn
            style="text-transform: none; font-size: 16px"
            width="208px"
            height="39px"
            class="white--text"
            color="#085895"
            @click="signUp"
            >Зареєструватися</v-btn
          >
          <v-row no-gutters align="center">
            <v-col>
              <v-divider class="mt-10 mb-5 mr-5 px-15 divider" />
            </v-col>
            <span style="margin-top: 15px">або</span>
            <v-col> <v-divider class="mt-10 mb-5 ml-5 px-15 divider" /> </v-col
          ></v-row>
          <social-btn @close="$emit('close')" />
          <div style="margin-bottom: 68px">
            <span class="signInTextStyle pointer" @click="$emit('goToSignIn')"
              >Увійти</span
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import modalHeader from "@/components/UI/modalHeader.vue";
import { validationMixin } from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import authService from "@/requests/admin/authService";
import { mapActions } from "vuex";
import modalMixin from "@/mixins/modalMixin";
import SuccessSnackbar from "@/components/UI/successSnackbar.vue";
import SocialBtn from "@/components/UI/buttons/socialBtn.vue";
export default {
  mixins: [validationMixin, modalMixin],
  components: {
    SuccessSnackbar,
    SocialBtn,
    //modalHeader,
  },
  data: () => ({
    user: {},
    emailErros: [],
    showPassword: false,
    showConfirmPassword: false,
    showSuccessSnackbar: false,
    isAgree: false,
    snackbarText: "",
  }),
  validations: {
    user: {
      username: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      password_confirm: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    ...mapActions(["updateInfo"]),
    async signUp() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.isAgree) {
        let user = new FormData();
        user.append("first_name", this.user.username);
        user.append("last_name", this.user.last_name);
        user.append("email", this.user.email);
        user.append("password", this.user.password);
        await authService
          .signUp(user)
          .then((data) => {
            this.snackbarText = "Користувач успішно створений";
            this.showSuccessSnackbar = true;
            setTimeout(() => {
              this.showSuccessSnackbar = false;
            }, 3000);
            this.updateInfo({
              token: data.authorisation.token,
            });
            this.$emit("goToSignIn");
          })
          .catch((e) => {
            let response = e.response.data;
            if (response.status == "failed") {
              if (
                response.data.email[0] == "The email has already been taken."
              ) {
                this.emailErros.push("Користувач з таким email вже існує");
              }
            }
          });
      }
    },
  },
  computed: {
    usernameError() {
      const errors = [];
      if (!this.$v.user.username.$dirty) {
        return errors;
      }
      !this.$v.user.username.required &&
        errors.push("Поле прізвище обов'язкове");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      !this.$v.user.email.required && errors.push("Поле email обов'язкове");
      if (!this.$v.user.email.email) {
        errors.push("Введено некоректний email");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      if (
        (!this.$v.user.password.valid && this.$v.user.password.required) ||
        (!this.$v.user.password.minLength && this.$v.user.password.required)
      ) {
        errors.push(
          "Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру, спецсимвол, велику та маленьку літери"
        );
        return errors;
      }
      !this.$v.user.password.required && errors.push("Поле пароль обов'язкове");
      return errors;
    },
    passwordConfirmError() {
      const errors = [];
      if (!this.$v.user.password_confirm.$dirty) {
        return errors;
      }
      if (!this.$v.user.password_confirm.sameAsPassword) {
        errors.push("Паролі не збігаються");
        return errors;
      }
      !this.$v.user.password_confirm.required &&
        errors.push("Поле повторити пароль обов'язкове");
      return errors;
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <v-footer
    color="#122B3E"
    :height="$vuetify.breakpoint.mdAndDown ? 'max-content' : '400px'"
    :style="$vuetify.breakpoint.md ? 'padding: 30px 0px' : ''"
  >
    <v-row justify="start" v-if="!$vuetify.breakpoint.smAndDown">
      <v-col
        cols="2"
        xl="2"
        lg="2"
        md="2"
        sm="4"
        style="text-align: left"
        :style="
          $vuetify.breakpoint.md ? 'padding-left: 10px' : 'padding-left: 50px'
        "
      >
        <img
          width="136px"
          height="100px"
          src="@/assets/img/logoMbus.png"
          alt="logo"
          loading="lazy"
        />
      </v-col>
      <v-col
        cols="3"
        xl="2"
        lg="2"
        md="3"
        sm="4"
        class="white--text"
        style="text-align: left"
      >
        <span class="sectionTitle">ТЕЛЕФОНИ ДИСПЕТЧЕРІВ</span>
        <v-row
          align="center"
          no-gutters
          v-for="item in phoneNumber"
          :key="item.id"
          class="mt-2"
        >
          <v-icon small color="white" class="mr-2">mdi-phone</v-icon>
          <span>
            <a
              :href="`tel: ${item.number}`"
              style="text-decoration: none; color: white"
            >
              {{ item.number }}</a
            ></span
          >
          <img
            v-if="item.type_number == 'UA'"
            src="@/assets/img/UA.png"
            class="ml-2"
            width="15px"
            height="15px"
            loading="lazy"
            alt="Український номер телефону"
          />
          <img
            v-if="item.type_number == 'PL'"
            src="@/assets/img/PL.png"
            class="ml-2"
            width="15px"
            height="15px"
            loading="lazy"
            alt="Польський номер телефону"
          />
        </v-row>
      </v-col>
      <v-col
        cols="3"
        class="white--text"
        style="text-align: left"
        :style="
          $vuetify.breakpoint.md ? 'padding-left: 10px' : 'padding-left: 40px'
        "
        xl="3"
        lg="3"
        md="4"
        sm="4"
      >
        <span class="sectionTitle">КОНТАКТНА ІНФОРМАЦІЯ</span>
        <v-row no-gutters align="center" class="mt-2 mb-2">
          <v-icon color="white" small class="mr-2">mdi-email</v-icon>
          <v-col cols="10">
            <span>
              <a
                :href="`mailto:${email}`"
                style="text-decoration: none; color: white"
              >
                {{ email.value }}
              </a></span
            >
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-icon color="white" small class="mr-2"
            >mdi-map-marker-radius</v-icon
          >
          <v-col cols="10">
            <span>{{ address.value }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="3"
        class="white--text"
        style="text-align: left; padding-left: 40px"
        xl="3"
        lg="3"
        md="3"
        sm="4"
        :style="
          $vuetify.breakpoint.md ? 'padding-left: 10px' : 'padding-left: 40px'
        "
      >
        <span class="sectionTitle">Умови використання</span>
        <v-row no-gutters align="center" class="mt-2 mb-2">
          <router-link
            to="/transportation_rules"
            style="text-decoration: none; color: white"
          >
            <span>Правила перевезень</span>
          </router-link>
        </v-row>
        <v-row no-gutters align="center" class="mt-2 mb-2">
          <router-link
            to="/rules_and_general_terms"
            style="text-decoration: none; color: white"
          >
            <span>Політика конфіденційності та загальні умови</span>
          </router-link>
        </v-row>
        <v-row no-gutters align="center" class="mt-2 mb-2">
          <router-link
            to="/about_us"
            style="text-decoration: none; color: white"
          >
            <span>Про нас</span>
          </router-link>
        </v-row>
        <v-row no-gutters align="center" class="mt-2 mb-2">
          <a
            href="https://mbus.if.ua/payment_and_return_conditions"
            style="text-decoration: none; color: white"
          >
            <span>Оплата та умови повернення</span>
          </a>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.lgAndUp"
        style="text-align: left; padding-left: 40px"
        class="white--text"
        :style="
          $vuetify.breakpoint.md ? 'padding-left: 10px' : 'padding-left: 40px'
        "
      >
        <span class="sectionTitle">СОЦІАЛЬНІ МЕРЕЖІ</span>
        <v-row no-gutters class="mt-2" align="start">
          <a
            v-for="item in socialNetworks"
            :key="item.id"
            :href="item.value"
            target="_blank"
            class="mr-2"
            :aria-label="item.key"
          >
            <img
              width="24px"
              height="24px"
              :src="item?.image_id?.path"
              alt="viber"
              loading="lazy"
              class="mb-1 mt-1"
            />
          </a>
        </v-row>
        <img
          class="mt-5"
          src="@/assets/img/visaMasterCardLogo.png"
          alt="visa-master-card"
          width="100px"
          height="30px"
          loading="lazy"
        />
      </v-col>
      <v-col
        cols="12"
        v-if="$vuetify.breakpoint.md"
        style="text-align: left"
        class="white--text"
      >
        <v-row no-gutters justify="center">
          <v-col cols="3" style="text-align: center">
            <span class="sectionTitle">СОЦІАЛЬНІ МЕРЕЖІ</span>
            <v-row no-gutters class="mt-2" align="start" justify="center">
              <a
                v-for="item in socialNetworks"
                :key="item.id"
                :href="item.value"
                target="_blank"
                class="mr-2"
                :aria-label="item.key"
              >
                <img
                  width="24px"
                  height="24px"
                  :src="item?.image_id?.path"
                  alt="viber"
                  loading="lazy"
                  class="mb-1 mt-1"
                />
              </a>
            </v-row>
            <img
              class="mt-5"
              src="@/assets/img/visaMasterCardLogo.png"
              alt="visa-master-card"
              width="100px"
              height="30px"
              loading="lazy"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-row
        no-gutters
        align="center"
        justify="center"
        :style="$vuetify.breakpoint.md ? '' : 'margin-top: 56px'"
      >
        <span
          style="
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: white;
          "
          >© MBUS 2022 - {{ new Date().getFullYear() }}
          <span style="color: #636969">|</span> Develop by</span
        >
        <a target="_blank" href="http://bmax.com.ua/" rel="nofollow">
          <img
            src="@/assets/img/BMaxLogo.png"
            class="pointer"
            style="margin-left: 8px; margin-top: 5px"
            width="80px"
            height="40px"
            alt="BMax - Компанія розробник"
            loading="lazy"
          />
        </a>
      </v-row>
    </v-row>
    <v-row justify="center" class="pt-2 pb-15" v-else>
      <v-col>
        <img
          width="80px"
          height="60px"
          src="@/assets/img/logoMbus.png"
          alt="logo"
          loading="lazy"
        />
        <v-col style="text-align: center" class="white--text">
          <span>СОЦІАЛЬНІ МЕРЕЖІ</span>
          <v-row no-gutters class="mt-2" align="start" justify="center">
            <a
              v-for="item in socialNetworks"
              :key="item.id"
              :href="item.value"
              target="_blank"
              class="mr-2"
              :aria-label="item.key"
            >
              <img
                width="24px"
                height="24px"
                :src="item?.image_id?.path"
                alt="viber"
                class="mb-1 mt-1"
                loading="lazy"
              />
            </a>
          </v-row>
        </v-col>
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="margin-top: 38px"
        >
          <span
            style="
              font-weight: 400;
              font-size: 12px;
              line-height: 17px;
              color: #eeeeee;
            "
            >Develop by</span
          >
          <a target="_blank" href="http://bmax.com.ua/" rel="nofollow">
            <img
              src="@/assets/img/BMaxLogo.png"
              class="pointer"
              style="margin-left: 8px; margin-top: 5px"
              width="60px"
              height="30px"
              alt="BMax - Компанія розробник"
              loading="lazy"
            />
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import settingsService from "@/requests/admin/settingsService";
import parsePhoneNumber from "libphonenumber-js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "appFooter",
  data: () => ({
    socialNetworks: [],
    phoneNumber: [],
    email: "",
    address: "",
  }),
  mounted() {
    this.getSettings();
  },
  methods: {
    ...mapActions(["updatePhoneNumbers"]),
    async getSettings() {
      await settingsService.getSettingList().then((res) => {
        if (res.status == "Success") {
          this.email =
            res.data.filter((data) => data.key == "support_email")?.[0] || "";
          this.address =
            res.data.filter((data) => data.key == "address")?.[0] || "";
          let phoneNumbersForOrder = res.data.filter(
            (data) => data.type == "contact"
          );
          let phoneDispatchersNumbers = res.data.filter(
            (data) => data.type == "dispatchers"
          );
          this.socialNetworks = res.data.filter(
            (data) => data.type == "socials"
          );
          let phoneNumbers = [];
          phoneNumbersForOrder.forEach((number) => {
            phoneNumbers.push({
              id: number.id,
              number: number.value,
              type: number.type,
              type_number: parsePhoneNumber(number.value, "US")?.country,
            });
          });
          phoneDispatchersNumbers.forEach((number) => {
            phoneNumbers.push({
              id: number.id,
              number: number.value,
              type: number.type,
              type_number: parsePhoneNumber(number.value, "US")?.country,
            });
          });
          this.updatePhoneNumbers(phoneNumbers);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["phoneNumbers"]),
  },
  watch: {
    phoneNumbers: {
      deep: true,
      handler() {
        this.phoneNumber = Object.entries(this.phoneNumbers)
          .map(([key, value]) => ({ id: key, ...value }))
          .filter((number) => number.type == "dispatchers");
      },
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.sectionTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
}
</style>